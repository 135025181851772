<template>
  <div>
    <div class="carregando" v-if="loading">
      <div>
        <img v-if="!isHeaven" src="@/assets/img/_logo.svg" alt="logo-loading" class="logo-loading" />
        <img v-else src="@/assets/img/heaven/logo.svg" alt="logo-loading" class="logo-loading" />
        <span class="loading-carregando" :class="{'heaven': isHeaven}"></span>
      </div>
    </div>

    <b-container fluid class="p-0" v-if="!loading && permition">
      <iframe
        :src="paginaObrigado || thank_you_page_default"
        class="iframe"
      ></iframe>
    </b-container>

    <!-- modals -->
    <SuccessModal
      :vendas="todasVendas"
      :valor="dados_geral.valor"
      :parcelas="dados_geral.parcelas"
      :boleto_url="dados_geral.url"
      :boleto_barcode="dados_geral.barcode"
      :trial="dados_geral.trial"
      :name_product="dados_geral.name_product"
      :type="dados_geral.type"
      :boletos="dados_geral.boletos"
      v-if="showModal"
      @close="redirect"
    />
    <SuccessModalPix
      :vendas="todasVendas"
      :qrcode="dados_pix.qrcode"
      :imgQrcode="dados_pix.imgQrcode"
      :valor="dados_pix.valor"
      :name_product="dados_pix.name_product"
      :frete="dados_pix.frete"
      :router_id="router_id"
      :dadosPix="dados_pix"
      v-if="showModalPix"
      @close="redirect"
    />
  </div>
</template>

<script>
import { EventBus } from '@/main.js'

// componentes:
import SuccessModal from '@/components/Checkout/SuccessModal'
import SuccessModalPix from '@/components/Checkout/SuccessModalPix'
import { sanitizeUrl } from '@/utils/url.js'

//services:
import SaleService from '@/services/resources/SaleService'
import ProductService from '@/services/resources/ProductService'
import GlobalSettingsService from '@/services/resources/GlobalSettingsService'
import CheckoutService from '@/services/resources/CheckoutService'
import UpsellService from '@/services/resources/UpsellService'

const serviceSale = SaleService.build()
const serviceProduct = ProductService.build()
const serviceGlobalSettings = GlobalSettingsService.build()
const serviceCheckout = CheckoutService.build()
const upsellCheckout = UpsellService.build()

export default {
  name: 'ThankYou',
  components: { SuccessModal, SuccessModalPix },
  props: {
    offer: {
      type: String,
      required: false,
      default: () => null
    },
    dados_response: {
      default: () => [],
    },
    type: {
      type: String,
    },
    // flag indicando que está vindo do checkout
    isCheckout: {
      type: Boolean,
      default: false,
    },
    // METODOS DE PAGAMENTO
    methodCheckout: {
      type: String,
    },
    valor: {
      type: Number,
      default: 0,
    },
    parcelas: {
      type: Number,
      default: 1,
    },
    trial: {},

    // boleto
    boleto_url: {
      type: String,
    },
    boleto_barcode: {
      type: String,
    },
    thank_you_page: {
      type: String,
    },
    // pix
    qrcode: {
      type: String,
    },
    imgQrcode: {
      type: String,
    },
    pix: {
      type: Boolean,
    },
    name_product: {
      type: String,
    },
  },
  data() {
    return {
      router_id: -1,
      response: [],
      todasVendas: [],
      loading: false,
      permition: false,
      upsell_id: null,
      affiliate_id: null,
      id_product: "",
      product: {},
      id_sale: "",
      sale: null,
      query: {},
      paginaObrigado: "",
      thank_you_page_default: "https://greenn.com.br/checkout-obrigado",
      method: "",
      dados_geral: {
        valor: 0,
        parcelas: 1,
        trial: 0,
        url: "",
        barcode: "",
        boletos: []
      },
      dados_pix: {
        isPix: false,
        valor: 0,
        qrcode: "",
        imgQrcode: "",
        name_product: "",
      },
      showModal: false,
      showModalPix: false,
      event_was_fired: false,
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    onPurchaseEvent() {
      const productsItems = this.todasVendas.map((item) => {
        return {
          amount: item.total || item.amount || item.product.amount,
          product_id: item.product.id,
          product_name: item.product.name,
        };
      });

      dataLayer.push({
        event: "purchase",
        purchase: {
          sale_id: +this.id_sale || +this.query.s_id,
          method: this.methodCheckout || this.response.sales[0].method,
          products: productsItems,
        },
      });
    },
    // caso fechar o modal
    // redireciona para a pagina de obrigado fora
    // do frame
    redirect(value) {
      this.showModal = false;
      this.showModalPix = false;
      let token = "";
      if (value) {
        token = `token=${value}`;
      }
      if (this.paginaObrigado) {
        const has_query = this.paginaObrigado.includes("?");
        const queryString = new URLSearchParams(this.query)

        if (Object.keys(this.query).length) {
          let url = `${this.paginaObrigado}${has_query ? "&" : "?"}${queryString.toString()}`;
          window.location.href = url;
        }
      } else window.location.href = `${this.thank_you_page_default}?${token}`;
    },
    // puxa os dados da venda
    fetchSale(bump = null) {
      this.loading = true;
      let data = {
        id: this.id_sale || this.query.s_id,
      };
      if(bump) data.id = bump.sale_id

      if (data.id && data.id != "delayed") {
        serviceSale
          .read(data)
          .then((response) => {
            if(!bump) this.response = response;
            this.sale = bump ? null : response.sales;

            if(!bump) {
              this.selectCountry(response.sales && response.sales.length && response.sales[0].country_code ? response.sales[0].country_code : "US")
              this.sale.forEach((venda) => {
                if(venda.method === "SAFETYPAY-CASH" || venda.method === "EFECTY" || venda.method === "EFT" || venda.method === "SENCILLITO" || venda.method === "SPEI"){
                  venda.boleto_barcode = venda.boleto_url;
                }
                if(venda?.shipping_selected) {
                  venda.shipping_selected = JSON.parse(venda.shipping_selected)
                  venda.shipping_selected = { ...venda.shipping_selected, ...venda.shipping_selected.frete }
                }
                if(this.sale.length > 1){
                  if(["waiting_payment", "paid", "completed", "trialing"].includes(venda.status)){
                    this.todasVendas.push(venda);
                  }
                } else {
                  this.todasVendas.push(venda);
                }
              });

              if (this.sale.length) {
                document.title = `${this.todasVendas[0].product.name} | Greenn Pay`;
                this.method = this.todasVendas[0].method;
  
                let pageType = "";
                if (this.todasVendas[0].product.custom_thank_you_pages.length) {
                  pageType =
                    this.todasVendas[0].product.custom_thank_you_pages.find(
                      (item) => item.type == this.todasVendas[0].method
                    );
                }
  
                const self = this;
                if (this.query.up_id) {
                  const data = { id: `${this.query.up_id}/metas` }
                  upsellCheckout.read(data)
                    .then((response) => {
                      var configs = response.metas;
                      let hasUpsell = false;
                      for (var indice in configs) {
                        let config = configs[indice];
                        if (config.upsell_key === "link_obrigado") {
                          if (config.upsell_value) {
                            hasUpsell = true
                            self.paginaObrigado = config.upsell_value;
                          }
                        }
                      }
                      if (!hasUpsell) {
                        this.paginaObrigado = pageType
                          ? pageType.url
                          : this.todasVendas[0].product.thank_you_page;
                      }
                    })
                } else {
                  this.paginaObrigado = pageType
                    ? pageType.url
                    : this.todasVendas[0].product.thank_you_page;
                }
                
                this.validatePermition(
                  this.todasVendas[0].method,
                  this.todasVendas[0].status
                );
              } else {
                this.mountedPage(false, "");
              }
            } else {
              this.todasVendas.push({
                success: true,
                status: response.sales[0].status,
                sale_id: bump.sale_id,
                boleto_barcode: response.sales[0].boleto_barcode || null,
                boleto_expiration_date: response.sales[0].boleto_expiration_date || null,
                boleto_url: response.sales[0].boleto_url || null,
                token: null,
                product: {
                  name: response.sales[0].offer.name,
                  amount: response.sales[0].original_amount,
                  custom_thank_you_page: this.product.custom_thank_you_page,
                  id: response.sales[0].product.id,
                },
                has_shipping_fee: response.sales[0].has_shipping_fee,
                shipping_amount: response.sales[0].type_shipping_fee === 'FIXED' ? response.sales[0].amount_fixed_shipping_fee : response.sales[0].shipping_amount,
                shipping_selected: response.sales[0].shipping_selected,
              })
              if(response.sales[0].boleto_barcode && response.sales[0].boleto_url) {
                const boletos = [];
                boletos.push({
                  url: response.sales[0].boleto_url,
                  barCode: response.sales[0].boleto_barcode
                })
                this.dados_geral.boletos = boletos
              }
            }
          })
          .catch((err) => {
            console.error(err)
            this.mountedPage(false, "")
          })
          .finally(() => {
            this.todasVendas.sort((a, b) => {
              if( a.product.id === parseInt(this.$route.params.id_product)) {
                return -1
              } else if( b.product.id === parseInt(this.$route.params.id_product)) {
                return 1
              } else return 0
            })
          })
      } else if (data.id == "delayed") {
        this.todasVendas.push({
          status: "delayed",
          name: "",
        });
        this.validatePermition("DELAYED", "delayed");
      } else {
        setTimeout(() => {
          this.mountedPage(false, "");
        });
      }
    },
    async getProduct(bump = null) {
      let data = { id: this.id_product || this.$route.params.id_product }
      if(this.offer) data.id = `${data.id}/offer/${this.offer}`
      if(bump) data = { id: bump.id }
      
      serviceProduct
        .read(data)
        .then((response) => {
          if(!bump) this.product = response.data;
          document.title = `${this.product.name} | Greenn Pay`;

          let sales = [
            {
              success: true,
              status: "trialing",
              chc: bump ? bump.chc : (this.$route.query.chc ?? null),
              token: null,
              product: {
                name: bump ? response.data.name : this.product.name,
                amount: bump ? response.data.amount : this.product.amount,
                custom_thank_you_page: this.product.custom_thank_you_page,
                id: response.data.id,
              },
              has_shipping_fee: this.product.has_shipping_fee,
              shipping_amount: this.product.type_shipping_fee === 'FIXED' ? this.product.amount_fixed_shipping_fee : null,
              shipping_selected: null,
            },
          ];
          sales.forEach((venda) => {
            this.todasVendas.push(venda);
          });

          this.paginaObrigado = this.product.thank_you_page;
          if(Array.isArray(this.todasVendas) && this.todasVendas.some(x => x.boleto_barcode)) {
            this.method = "BOLETO";
          } else {
            this.method = "CREDIT_CARD";
          }
          this.validatePermition(this.method, "delayed");
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.todasVendas.sort((a, b) => {
            if( a.product.id === parseInt(this.$route.params.id_product)) {
              return -1
            } else if( b.product.id === parseInt(this.$route.params.id_product)) {
              return 1
            } else return 0
          })
        })
    },
    // valida a permissão em acessar a pagina, baseado  no status
    validatePermition(method, status) {
      switch (method.toUpperCase()) {
        case "CREDIT_CARD":
        case "DEBITCARD":
        case "TWO_CREDIT_CARDS":
        case "PAYPAL": {
          if (status == "paid" || status == "delayed") {
            this.mountedPage(true, method);
          } else {
            this.mountedPage(false, "");
          }
          break;
        }
        case "EFECTY":
        case "EFT":
        case "SENCILLITO":
        case "SPEI":
        case "OXXO":
        case "SAFETYPAY-CASH":
        case "BOLETO": {
          this.mountedPage(true, method);
          break;
        }
        case "PIX": {
          if (
            status == "paid" ||
            status == "waiting_payment" ||
            status == "delayed"
          ) {
            this.mountedPage(true, method);
          } else {
            this.mountedPage(false, "");
          }
          break;
        }
        default: {
          this.mountedPage(true, "");
        }
      }
    },
    // monta a pagina baseado no metodo de pagamento
    mountedPage(flag, method) {
      if (flag) {
        switch (method.toUpperCase()) {
          case "BOLETO":
          case "EFECTY":
          case "EFT":
          case "SENCILLITO":
          case "SPEI":
          case "OXXO":
          case "SAFETYPAY-CASH": {
            this.dados_pix.isPix = false;
            this.dados_geral = {
              valor:
                this.todasVendas.reduce(
                  (total, venda) => total + venda.amount,
                  0
                ) || this.product.amount,
              trial: this.todasVendas[0].offer ? this.todasVendas[0].offer.trial : this.product.trial,
              frete: this.frete,
            };
            
            if (this.response.order) {
              this.dados_geral = {
                ...this.dados_geral,
                parcelas:
                  this.todasVendas.length === 1
                    ? this.todasVendas[0].installments
                    : this.response.order.installments,
                url:
                  this.todasVendas.length === 1
                    ? this.todasVendas[0].boleto_url
                    : this.response.order.boleto_url,
                barcode:
                  this.todasVendas.length === 1
                    ? this.todasVendas[0].boleto_barcode
                    : this.response.order.boleto_barcode,
              }
            } else if (Array.isArray(this.response.sales) && this.response.sales.some(item => item.type === 'SUBSCRIPTION' || item.type === 'TRANSACTION')) {
              const boletos = [];
              this.response.sales.forEach(sale => {
                boletos.push({
                  url: sale.boleto_url,
                  barCode: sale.boleto_barcode,
                })
              })
              this.dados_geral = {
                valor:
                  this.todasVendas.reduce(
                    (total, venda) => total + venda.amount,
                    0
                  ) || this.product.amount,
                trial: this.todasVendas[0].offer ? this.todasVendas[0].offer.trial : this.product.trial,
                frete: this.frete,
                boletos
              }
            } else {
              const boletos = [];
              this.todasVendas.forEach(sale => {
                if(sale.boleto_url && sale.boleto_barcode) {
                  boletos.push({
                    url: sale.boleto_url,
                    barCode: sale.boleto_barcode,
                  })
                }
                if(sale.chc && sale.chc === this.$route.query.chc) {
                  this.dados_geral.name_product = sale.product.name
                }
              })
              this.dados_geral.boletos = boletos
            }
            break;
          }
          case "PIX": {
            this.dados_pix = {
              isPix: true,
              valor:
                this.todasVendas.reduce(
                  (total, venda) => total + venda.amount,
                  0
                ) || this.product.amount,
              name_product:
                this.todasVendas[0].product.name || this.product.name,
              frete: this.frete,
            };
            if (this.response.order || this.todasVendas.length === 1) {
              this.dados_pix = {
                ...this.dados_pix,
                qrcode:
                  this.todasVendas.length === 1
                    ? this.todasVendas[0].qrcode
                    : this.response.order.qrcode,
                imgQrcode:
                  this.todasVendas.length === 1
                    ? this.todasVendas[0].imgQrcode
                    : this.response.order.imgQrcode,
              }
            } else if (this.response.sales.some(item => item.type === 'SUBSCRIPTION')) {
              this.dados_pix = {
                ...this.dados_pix,
                sales: this.todasVendas.map(item => {
                  return {
                    qrCode: item.qrcode,
                    imgQrCode: item.imgQrcode
                  }
                })
              }
            }
            break;
          }
          case "CREDIT_CARD":
          case "DEBITCARD":
          case "TWO_CREDIT_CARDS":
          case "PAYPAL": {
            this.dados_pix.isPix = false;
            this.dados_geral = {
              valor:
                this.todasVendas.reduce(
                  (total, venda) => total + venda.amount,
                  0
                ) || this.product.amount,
              parcelas: this.parcelas,
              name_product:
                this.todasVendas[0].product.name || this.product.name,
              trial: this.todasVendas[0].offer ? this.todasVendas[0].offer.trial : this.product.trial,
              type: this.type,
              frete: this.frete,
            };
            break;
          }
          case "DELAYED": {
            this.dados_pix.isPix = false;
            this.dados_geral = {
              valor: 0,
              parcelas: 1,
              url: "",
              trial: 0,
            };
            break;
          }
        }
        this.openModalPayment();
      } else {
        this.permition = false;
        this.loading = true;
        if (document.querySelector(".carregando"))
          document.querySelector(".carregando").classList.add("erro");
      }
    },
    setEventPurchase() {
      if(!this.event_was_fired) {

        const amount = this.dados_pix.isPix
          ? this.dados_pix.valor
          : this.dados_geral.valor;

        const sale_id = this.id_sale || this.query.s_id;
        const chc_id = this.$route.query.chc ? this.$route.query.chc : ''
      
        window.pixel.handleEvent(
          "conversion",
          this.id_product,
          this.affiliate_id,
          this.method,
          amount,
          this.sale && this.sale[0].offer ? this.sale[0].offer.amount : null,
          sale_id,
          chc_id
        )

        this.event_was_fired = true
        this.getTagManager()
      }
    },
    getTagManager() {
      let data = {
        id: this.id_product,
      };

      serviceCheckout.readAll(data).then((response) => {
        const resp = JSON.parse(response.data);
        const pixels = resp.data.pixels;

        const hasTagManager =
          pixels && pixels.length
            ? pixels.filter((item) => item.type == "GOOGLETAGMANAGER")
            : [];

        // fluxo tagmanager manual
        if (hasTagManager.length) {
          if (!this.isCheckout) {
            hasTagManager.forEach((item) => {
              (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                  "gtm.start": new Date().getTime(),
                  event: "gtm.js",
                });
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l != "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, "script", "dataLayer", item.pixel_id);
            });
          }

          // enviando o evento de checkout para o tagmanager
          this.onPurchaseEvent();
        }
      });
    },
    openModalPayment() {
      if (this.dados_pix.isPix) {
        this.showModalPix = true
        setTimeout(() => {
          this.$bvModal.show("success-modal-pix");
        }, 50)
      } else {
        this.showModal = true
        setTimeout(() => {
          this.$bvModal.show("success-modal");
        }, 50)
      }
      this.permition = true;
      this.loading = false;
      this.setEventPurchase();
    },
    setLocale(newLocale) {
      let data = {
        locale: newLocale,
      };
      EventBus.$emit("changeLocale", data);
    },
    getGlobalSettings() {
      let data = {
        keys: ["PAGARME_ANTIFRAUDE", "MONTHLY_INTEREST"],
      };

      serviceGlobalSettings
        .search(data)
        .then((response) => {
          this.pais = [...response].find((item) => item.country);
          this.pais = this.pais ? this.pais.country : "US";
        })
        .catch((err) => {
          this.pais = "BR";
          console.error(err);
        })
        .finally(() => {
          this.setLocale(this.pais);
        });
    },
    selectCountry(locale) {
      if (locale === "BR") {
        this.$i18n.locale = "pt";
      } else if (
        locale == "AR" ||
        locale == "FR" ||
        locale == "IT" ||
        locale == "IL" ||
        locale == "JP" ||
        locale == "ML" ||
        locale == "PR" ||
        locale == "PY" ||
        locale == "PA" ||
        locale == "BO" ||
        locale == "CR" ||
        locale == "DO" ||
        locale == "HN" ||
        locale == "CO" ||
        locale == "ES" ||
        locale == "GT" ||
        locale == "CL" ||
        locale == "EC" ||
        locale == "MX" ||
        locale == "PE" ||
        locale == "AR" ||
        locale == "UY"
      ) {
        this.$i18n.locale = "es";
      } else if(
        locale == "UK" ||
        locale == "US" ||
        locale == "AU" ||
        locale == "CA"
      ) {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "en";
      }
    },
  },
  mounted() {
    this.loading = true;
    if (!this.isCheckout) {
      if (this.id_sale || this.query.s_id) { // TRANSACTION
        // puxa os dados da venda
        this.fetchSale()
        this.router_id = this.id_sale || this.query.s_id
      } else { // SUBSCRIPTION
        // Produto principal
        this.getProduct()
        // Produtos order bump
        const queryKeys = Object.keys(this.$route.query)
        let bumps = []
        queryKeys.forEach(x => {
          if(x.substr(0, 4) === 'b_id') {
            if(this.$route.query[x].includes('-chc_')) { // SUBSCRIPTION
              if(this.$route.query[x].includes('-s_id_')) {
                bumps.push({
                  id: this.$route.query[x].split('-chc_')[0],
                  chc: (this.$route.query[x].split('-chc_')[1]).split('-s_id_')[0],
                  sale_id: this.$route.query[x].split('-s_id_')[1]
                })
              } else {
                bumps.push({
                  id: this.$route.query[x].split('-chc_')[0],
                  chc: this.$route.query[x].split('-chc_')[1]
                })
              }
            } else { // TRANSACTION
              bumps.push({
                id: this.$route.query[x].split('-s_id_')[0],
                sale_id: this.$route.query[x].split('-s_id_')[1]
              })
            }
          }
        })
        if(bumps.length > 0) {
          bumps.forEach(bump => {
            bump.sale_id ? this.fetchSale(bump) : this.getProduct(bump)
          })
        }
      }
    } else {
      let sales = this.dados_response.sales;
      sales.forEach((venda) => {
        this.todasVendas.push(venda);
      });

      this.todasVendas[0].installments = this.parcelas;
      this.method = this.methodCheckout;
      this.paginaObrigado = this.thank_you_page;
      switch (this.methodCheckout) {
        case "PIX": {
          this.dados_pix = {
            isPix: true,
            valor: this.valor,
            qrcode: this.qrcode,
            imgQrcode: this.imgQrcode,
            name_product: this.name_product,
          };
          break;
        }
        case "BOLETO": {
          this.dados_pix.isPix = false;
          this.dados_geral = {
            valor: this.valor,
            parcelas: this.parcelas,
            name_product: this.name_product,
            url: this.boleto_url,
            barcode: this.boleto_barcode,
            trial: this.trial || 0,
          };
          break;
        }
        case "CREDIT_CARD":
        case "DEBITCARD":
        case "TWO_CREDIT_CARDS":
        case "PAYPAL": {
          this.dados_pix.isPix = false;
          this.dados_geral = {
            valor: this.valor,
            parcelas: this.parcelas,
            name_product: this.name_product,
            trial: this.trial || 0,
          };
          break;
        }
      }

      this.openModalPayment();
    }
  },
  created() {
    sanitizeUrl(this.$route);
    // todas os parametros get da url
    this.query = this.$route.query;

    // upsell id
    if (typeof this.query.up_id != "undefined") {
      this.upsell_id = this.query.up_id;
    }

    // afiliado id
    if (typeof this.query.a_id != "undefined") {
      this.affiliate_id = this.query.a_id;
    }

    // seta o id da venda
    if (typeof this.query.s_id != "undefined") {
      this.id_sale = this.query.s_id;
    }
    if (typeof this.$route.params.id_product != "undefined") {
      this.id_product = this.$route.params.id_product;
    }

    EventBus.$on("changeLocale", (data) => {
      localStorage.setItem("location", data.locale);
      if (!data.locale) {
        this.getGlobalSettings();
        return;
      }
      if (data.locale == "BR" || data.locale == "PT") {
        this.selectCountry("BR");
      } else if(
        data.locale == "AR" ||
        data.locale == "FR" ||
        data.locale == "IT" ||
        data.locale == "IL" ||
        data.locale == "JP" ||
        data.locale == "ML" ||
        data.locale == "PR" ||
        data.locale == "PY" ||
        data.locale == "PA" ||
        data.locale == "BO" ||
        data.locale == "CR" ||
        data.locale == "DO" ||
        data.locale == "HN" ||
        data.locale == "ES" ||
        data.locale == "GT" ||
        data.locale == "CO" ||
        data.locale == "CL" ||
        data.locale == "EC" ||
        data.locale == "MX" ||
        data.locale == "PE" ||
        data.locale == "UY"
      ) {
        this.selectCountry("ES");
      } else if(
        data.locale == "UK" ||
        data.locale == "US" ||
        data.locale == "AU" ||
        data.locale == "CA"
      ) {
        this.selectCountry("US");
      } else {
        this.selectCountry("US");
      }
    });
  }
}
</script>

<style lang="scss" scoped>
html {
  overflow: hidden;
}
.iframe {
  width: 100%;
  height: 100vh;
}
.carregando.erro::before {
  content: "Ops, não é possível carregar essa venda. Tente mais tarde.";
  background: #3483fa;
  font-size: 14px;
}
</style>
