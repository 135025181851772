import Rest from '@/services/Rest';

/**
 * @typedef {GlobalSettingsService}
 */
export default class GlobalSettingsService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/global-settings'
}
