<template>
  <div>
    <!-- venda normal -->
    <b-container fluid v-if="vendas.length && vendas[0].status != 'delayed'">
      <p class="paragrafo informativo" data-test="totalpix" v-if="show_details">
        {{ $t("pg_obrigado.pix.efetuando") }} {{ isHeaven ? 'Heaven Am LCC' : 'Greenn Pagamentos e Tecnologia LTDA' }}
        <!-- R$ {{ formatMoney(valor) }} -->
        {{ $t("pg_obrigado.pix.ref") }};
      </p>
      <p
        class="paragrafo informativo"
        data-test="totalpix"
        v-if="!show_details"
      >
        Seu código de pagamento via Pix expirou, veja maiores detalhes abaixo
      </p>

      <div class="segunda-opcao" v-if="show_details">
        <p class="paragrafo">
          {{ $t("pg_obrigado.pix.cole") }}
        </p>
      </div>

      <PixQrCode
        :imgQrcode="imgQrcode"
        :qrcode="qrcode"
        :sales="sales"
        @timerPix="timerPix"
        @closeModal="closeModal"
      />

      <b-row v-if="show_details && !loading && renderComponents">
        <b-col cols="12 mt-3">
          <h6 class="detalhes-compra">
            {{ $t("pg_obrigado.modal.detalhes_compra") }}
          </h6>
          <div
            class="item-venda"
            v-for="(venda, index) in vendas"
            :key="venda.sale_id || venda.bump_id"
          >
            <div class="dados-venda">
              <p>{{ $t("pg_obrigado.modal.codigo_transacao") }}</p>
              <p data-test="idsale">
                #{{ venda.sale_id || venda.id || venda.bump_id }}
              </p>
            </div>
            <div class="dados-venda">
              <p data-test="namesale">
                <font-awesome-icon
                  icon="times"
                  class="icon-status icon-error"
                  v-if="venda.status == 'error'"
                />
                <font-awesome-icon
                  icon="check"
                  class="icon-status icon-success"
                  v-if="venda.status == 'success'"
                />
                {{ venda.product ? venda.product.name : "--" }}

                <span class="motive-error" v-if="venda.status == 'error'">{{
                  checkError(venda.code)
                }}</span>

                <span class="motive-error" v-if="venda.status == 'success'"
                  >{{ $t("pg_obrigado.modal.transacao") }}</span
                >
              </p>
              <p v-if="venda.total" data-test="pricesale">
                R$
                {{ formatMoney(venda.total) }}
              </p>
              <p v-else-if="venda.amount" data-test="pricesale">
                R$
                {{ formatMoney(venda.amount) }}
              </p>
              <p v-else data-test="pricesale">
                R$
                {{ formatMoney(venda.product ? venda.product.amount : "") }}
              </p>
            </div>
            <div class="dados-venda" v-if="venda.shipping_amount">
              <p>frete</p>
              <p>R$ {{ formatMoney(venda.shipping_amount) }}</p>
            </div>
            <section class="w-100 d-flex" v-if="!imgQrcode && !qrcode">
              <b-button class="w-100 mr-2 mt-3 my-1" variant="outline-primary" @click="showOrHide(index)">Visualizar Qr Code</b-button>
              <b-button class="w-100 ml-2 mt-3 my-1" variant="primary" @click="copyQrcode(venda.qrcode)">Copiar código</b-button>
            </section>
            <section class="w-100 d-flex justify-content-center" v-if="qrcodeController[index] && renderComponents">
              <b-img
              :src="venda.imgQrcode"
              fluid
              alt="Pix Image"
              data-test="imgqrcode"
              data-anima="top"
              ></b-img>
            </section>
          </div>
        </b-col>
      </b-row>

      <div v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </b-container>

    <!-- venda delayed -->
    <b-container fluid v-if="vendas.length && vendas[0].status == 'delayed'">
      <h6 class="subtitulo">{{ $t("pg_obrigado.modal.agradecemos") }}</h6>
      <p class="paragrafo">
        {{ $t("pg_obrigado.modal.proximos_passos") }}

        <b-row v-if="Object.values(vendas).length ? vendas[0].product : false">
          <b-col cols="12 mt-1">
            <h6 class="detalhes-compra">
              {{ $t("pg_obrigado.modal.detalhes_compra") }}
            </h6>
            <div
              class="item-venda"
              v-for="venda in vendas"
              :key="`${venda.sale_id || venda.id || venda.bump_id}-${
                venda.product ? venda.product.name : ''
              }`"
            >
              <div class="dados-venda">
                <p>{{ $t("pg_obrigado.modal.codigo_transacao") }}</p>
                <p data-test="idsale">
                  #{{ venda.sale_id || venda.id || venda.bump_id }}
                </p>
              </div>
              <div class="dados-venda">
                <p data-test="namesale">
                  <font-awesome-icon
                    icon="times"
                    class="icon-status icon-error"
                    v-if="venda.status == 'error'"
                  />
                  <font-awesome-icon
                    icon="check"
                    class="icon-status icon-success"
                    v-if="venda.status == 'success'"
                  />
                  {{ venda.product ? venda.product.name : "--" }}
                  <span class="motive-error" v-if="venda.status == 'error'">{{
                    checkError(venda.code)
                  }}</span>

                  <span class="motive-error" v-if="venda.status == 'success'"
                    >{{ $t("pg_obrigado.modal.transacao") }}</span
                  >
                </p>
                <p v-if="venda.total" data-test="pricesale">
                  R$
                  {{ formatMoney(venda.total) }}
                </p>
                <p v-else-if="venda.amount" data-test="pricesale">
                  R$
                  {{ formatMoney(venda.amount) }}
                </p>
                <p v-else data-test="pricesale">
                  R$
                  {{ formatMoney(venda.product ? venda.product.amount : "") }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </p>
    </b-container>

    <!-- frete -->
    <FreteComprado :vendas="vendas" />
  </div>
</template>

<script>
import Money from "@/mixins/money";
import errorPayment from "@/utils/errorPayment.js";
import PixQrCode from "./PixQrCode";
import FreteComprado from "./FreteComprado";

export default {
  data() {
    return {
      loading: true,
      show_details: true,
      qrcodeController: new Array(this.vendas.length).fill(false),
      renderComponents: true
    };
  },
  name: 'DetalhesPix',
  mixins: [Money],
  components: { PixQrCode, FreteComprado },
  props: {
    vendas: {
      type: Array,
      default: () => [],
    },
    imgQrcode: {
      type: String,
    },
    qrcode: {
      type: String,
    },
    sales: {
      type: Array,
      required: false,
      default: () => null
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    closeModal(){
      this.$emit('closeModal')
    }, 
    timerPix(data) {
      this.show_details = data;
      this.$emit("show_details", data);
      this.loading = false;
    },
    checkError(error) {
      return errorPayment(error);
    },
    copyQrcode(qrcode = "") {
      navigator.clipboard.writeText(qrcode);
    },
    showOrHide(index){
      this.qrcodeController = new Array(this.vendas.length).fill(false)
      this.qrcodeController[index] = !this.qrcodeController[index]
      this.renderComponents = false;
      this.$nextTick(() => {
        this.renderComponents = true;
      })
    }
  },
};
</script>

<style>
</style>