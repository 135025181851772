<template>
  <b-modal
    id="success-modal"
    :title="amountFormat"
    :no-close-on-backdrop="true"
    size="lg"
    @hidden="onClose"
    @show="openModal"
  >
    <b-container v-if="(boleto_barcode_ || boleto_url) && vendas.length && vendas[0].status != 'delayed'"
      fluid
    >
      <div
        v-if="
          vendas[0].method != 'SAFETYPAY-CASH' &&
          vendas[0].method != 'SENCILLITO' &&
          vendas[0].method != 'SPEI' &&
          vendas[0].method != 'EFECTY' &&
          vendas[0].method != 'EFT' &&
          vendas[0].method != 'OXXO'
        "
      >
        <h6 class="subtitulo">{{ $t("pg_obrigado.modal.prazo_pagar") }}</h6>
        <p class="paragrafo">
          {{ $t("pg_obrigado.modal.internet_banking") }}
        </p>
        <p class="paragrafo">
          {{ $t("pg_obrigado.modal.recebe_email") }}
        </p>
        <p class="paragrafo">
          {{ $t("pg_obrigado.modal.compensacao") }}
        </p>
      </div>
      <div v-else>
        <h6 class="subtitulo">
          !Ahora paga tu {{ vendas[0].method.toUpperCase() }}
        </h6>
        <p class="paragrafo">
          Tu compra fue exitosa, a continuación tienes tus datos de
          {{ vendas[0].method.toUpperCase() }} para realizar el pago. Debe pagar
          a través de internet banking o ubicación autorizada antes del
          {{ formatDate(vendas[0].boleto_expiration_date) }}. Toda esta
          información se enviará a su correo electrónico. Después de realizar el
          pago, puede tomar hasta 3 días hábiles para que se liquide el pago.
        </p>
      </div>
      <p v-if="vendas.length && vendas[0].installments > 1"
        class="paragrafo mt-4"
      >
        ✨ {{ $t("pg_obrigado.modal.compra_parcelada") }}
        {{ vendas[0].installments }}x
      </p>
      <b-row>
        <b-col cols="12 mt-1">
          <h6 class="detalhes-compra">
            {{ $t("pg_obrigado.modal.detalhes_compra") }}
          </h6>
          <!-- Purchase Details -->
          <div
            class="item-venda"
            v-for="venda in vendas"
            :key="
              venda.sale_id ||
              venda.id ||
              venda.bump_id ||
              venda.chc_id ||
              venda.chc
            "
          >
            <div class="dados-venda">
              <p>
                {{
                  venda.product.trial
                    ? $t("pg_obrigado.modal.id_assinatura")
                    : $t("pg_obrigado.modal.codigo_transacao")
                }}
              </p>
              <p data-test="idsale">
                #{{
                  venda.sale_id ||
                  venda.id ||
                  venda.bump_id ||
                  venda.chc_id ||
                  venda.chc
                }}
              </p>
            </div>
            <div class="dados-venda">
              <p data-test="namesale">
                <font-awesome-icon v-if="venda.status == 'error'"
                  icon="times"
                  class="icon-status icon-error"
                />
                <font-awesome-icon v-if="venda.status == 'success' || venda.status == 'paid' || venda.status == 'trialing'"
                  icon="check"
                  class="icon-status icon-success"
                />
                {{ venda.product ? venda.product.name : "---" }}
                <span v-if="venda.status == 'error'" class="motive-error">
                  {{ checkError(venda.code) }}
                </span>
                <span v-if="venda.status == 'success' || venda.status == 'paid' || venda.status == 'trialing'"
                  class="motive-error"
                >
                  {{ $t("pg_obrigado.modal.transacao") }}
                </span>
              </p>
              <p data-test="pricesale">
                {{
                  venda.local_currency && venda.local_currency.currency_symbol
                    ? venda.local_currency.currency_symbol
                    : "R$"
                }}
                {{
                  formatMoney(
                    venda.original_amount || venda.total || venda.product.amount || venda.amount
                  )
                }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-if="(boleto_barcode_ || boleto_url) && vendas.length && vendas[0].status != 'delayed'"
      class="my-3 action-item"
    >
      <input v-if="boleto_barcode_"
        v-model="boleto_barcode_"
        disabled
        class="form-copy"
        :id="`form-boleto-1`"
        data-test="barcode"
      />
      <input v-if="boleto_url && !boleto_barcode_"
        v-model="boleto_url"
        disabled
        class="form-copy"
        :id="`form-boleto-1`"
        data-test="barcode"
      />
      <div>
        <!-- Tiket Barcode -->
        <b-button v-if="
            boleto_barcode_ &&
            vendas[0].method !== 'SAFETYPAY-CASH' &&
            vendas[0].method != 'SENCILLITO' &&
            vendas[0].method != 'SPEI' &&
            vendas[0].method !== 'EFECTY' &&
            vendas[0].method !== 'EFT' &&
            vendas[0].method !== 'OXXO'
          "
          variant="info"
          class="mr-3 btn-modal"
          data-test="copyboleto"
          @click="copy()"
        >
          {{ $t("pg_obrigado.modal.btn_codigo") }}
        </b-button>
        <!-- Copy Tiket -->
        <b-button v-if="
            (boleto_barcode_ || boleto_url) &&
            (vendas[0].method === 'SAFETYPAY-CASH' ||
              vendas[0].method === 'SENCILLITO' ||
              vendas[0].method === 'SPEI' ||
              vendas[0].method === 'EFECTY' ||
              vendas[0].method === 'EFT' ||
              vendas[0].method === 'OXXO')
          "
          variant="info"
          class="mr-3 btn-modal"
          data-test="copyboleto"
          @click="copy()"
        >
          {{ $t("pg_obrigado.modal.btn_codigo2") }}
        </b-button>
        <!-- Oxxo -->
        <!-- <b-button v-if="boleto_url && vendas[0].method === 'OXXO'"
          class="btn-modal"
          data-test="downloadboleto"
          @click="openLinkOxxo()"
        >
          Enlace Abierto
        </b-button> -->
        <!-- Download Tiket -->
        <b-button v-if="boleto_url"
          class="btn-modal"
          data-test="downloadboleto"
          @click="download(boleto_url)"
        >
          {{ $t("pg_obrigado.modal.imprimir_boleto") }}
        </b-button>
      </div>
    </div>
    <!-- Paragraph -->
    <div v-if="
        (boleto_barcode_ || boleto_url) && 
        (vendas[0].method === 'SAFETYPAY-CASH' ||
          vendas[0].method === 'SENCILLITO' ||
          vendas[0].method === 'SPEI' ||
          vendas[0].method === 'EFECTY' ||
          vendas[0].method === 'EFT' ||
          vendas[0].method === 'OXXO')
      "
      class="mt-3"
    >
      <p class="paragrafo">
        Si han pasado 72 horas desde el pago y no se han enviado los datos de
        acceso, por favor contacta con el vendedor para comprobar qué ha podido
        pasar. Revisa también tu buzón de SPAM, en algunos casos puede haber
        problemas al enviar el correo. Te pedimos que siempre revises para no
        perder los datos de acceso que te enviamos cuando se aprueba el pago.
      </p>
    </div>
    <b-container v-if="(!boleto_barcode_ && !boleto_url) && vendas.length && vendas[0].status != 'delayed'"
      fluid
    >
      <h6 class="subtitulo">{{ $t("pg_obrigado.modal.agradecemos") }}</h6>
      <p class="paragrafo">
        {{ $t("pg_obrigado.modal.vc_adquiriu") }}
        {{ name_product ? name_product : "produto" }}
      </p>
      <p class="paragrafo">{{ $t("pg_obrigado.modal.detalhes_email") }}</p>

      <p v-if="trial" class="paragrafo" data-test="trialsale">
        ✨ {{ $t("pg_obrigado.modal.produto_trial") }} {{ trial }}
        {{ trial > 1 ? $t("pg_obrigado.modal.dias_teste") : $t("pg_obrigado.modal.dia_teste") }}
      </p>

      <p v-if="vendas.length && vendas[0].installments > 1"
        class="paragrafo mt-4"
        data-test="installmentssale"
      >
        ✨ {{ $t("pg_obrigado.modal.compra_parcelada") }}
        {{ vendas[0].installments }}x
      </p>

      <b-row v-if="Object.values(vendas).length ? vendas[0].product : false">
        <b-col cols="12 mt-1">
          <h6 class="detalhes-compra">
            {{ $t("pg_obrigado.modal.detalhes_compra") }}
          </h6>
          <div
            v-for="(venda, index) in vendas"
            :key="`${
              venda.sale_id ||
              venda.id ||
              venda.bump_id ||
              venda.chc_id ||
              venda.chc
            }-${venda.product ? venda.product.name : ''}`"
            class="item-venda"
          >
            <div class="dados-venda">
              <p>
                {{
                  venda.product.trial
                    ? $t("pg_obrigado.modal.id_assinatura")
                    : $t("pg_obrigado.modal.codigo_transacao")
                }}
              </p>
              <p data-test="idsale">
                #{{
                  venda.sale_id ||
                  venda.id ||
                  venda.bump_id ||
                  venda.chc_id ||
                  venda.chc
                }}
              </p>
            </div>
            <div class="dados-venda">
              <p data-test="namesale">
                <font-awesome-icon v-if="venda.status == 'error'"
                  icon="times"
                  class="icon-status icon-error"
                />
                <font-awesome-icon v-if="venda.status == 'success' || venda.status == 'paid' || venda.status == 'trialing'"
                  icon="check"
                  class="icon-status icon-success"
                />
                {{ venda.product ? venda.product.name : "--" }}
                <span v-if="venda.status == 'error'" class="motive-error">
                  {{ checkError(venda.code) }}
                </span>
                <span v-if="venda.status == 'success' || venda.status == 'paid' || venda.status == 'trialing'"
                  class="motive-error"
                >
                  {{ $t("pg_obrigado.modal.transacao") }}
                </span>
              </p>
              <div v-if="venda.product.trial">
                <p data-test="pricesale">
                  {{ $t("checkout.pagamento.bump.free") }}
                </p>
              </div>
              <div v-else>
                <p v-if="venda.trial_with_shipping" data-test="pricesale">
                  {{
                    venda.local_currency && venda.local_currency.currency_symbol
                      ? venda.local_currency.currency_symbol
                      : "R$"
                  }}

                  {{ formatMoney(venda.product.amount) }}
                </p>
                <p v-else data-test="pricesale">
                  <template v-if="vendas[0].method === 'FREE'">
                    {{ $t('order.gratis') }}
                  </template>
                  <template v-else>
                    {{
                      venda.local_currency && venda.local_currency.currency_symbol
                        ? venda.local_currency.currency_symbol
                        : "R$"
                    }}
                    {{
                      venda.method === 'BOLETO' && venda.type === 'SUBSCRIPTION'
                        ? venda.offer.no_interest_installments
                          ? formatMoney(venda.offer.amount)
                          : formatMoney(venda.amount)
                        : formatMoney(venda.original_amount || venda.total || venda.product.amount || venda.amount)
                    }}
                  </template>
                </p>
              </div>
            </div>
            <!-- Frete -->
            <div class="dados-venda" v-if="venda.shipping_amount">
              <p>Frete</p>
              <p>R$ {{ formatMoney(venda.shipping_amount) }}</p>
            </div>
            <!-- Boleto -->
            <section v-if="boletos.length" class="my-3 action-item">
              <template v-if="venda.status !== 'trialing' && boletos.findIndex((el, i, arr) => arr[index] !== -1)">
                <input
                  v-if="boletos[index].barCode"
                  v-model="boletos[index].barCode"
                  disabled
                  class="form-copy"
                  :id="`boleto-${index}`"
                  data-test="barcode"
                />
                <!-- Tiket Barcode -->
                <b-button v-if="boletos[index].barCode"
                  variant="info"
                  class="mr-3 btn-modal"
                  data-test="copyboleto"
                  @click="copyByElement(`boleto-${index}`)"
                >
                  {{ $t("pg_obrigado.modal.btn_codigo") }}
                </b-button>
                <!-- Download Tiket -->
                <b-button v-if="boletos[index].url"
                  class="btn-modal"
                  data-test="downloadboleto"
                  @click="download(boletos[index].url)"
                >
                  {{ $t("pg_obrigado.modal.imprimir_boleto") }}
                </b-button>
              </template>
              <template v-else>
                <input
                  v-if="venda.boleto_barcode"
                  v-model="venda.boleto_barcode"
                  disabled
                  class="form-copy"
                  :id="`boleto-${index}`"
                  data-test="barcode"
                />
                <!-- Tiket Barcode -->
                <b-button v-if="venda.boleto_barcode"
                  variant="info"
                  class="mr-3 btn-modal"
                  data-test="copyboleto"
                  @click="copyByElement(`boleto-${index}`)"
                >
                  {{ $t("pg_obrigado.modal.btn_codigo") }}
                </b-button>
                <!-- Download Tiket -->
                <b-button v-if="venda.boleto_url"
                  class="btn-modal"
                  data-test="downloadboleto"
                  @click="download(venda.boleto_url)"
                >
                  {{ $t("pg_obrigado.modal.imprimir_boleto") }}
                </b-button>
              </template>
            </section>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="vendas.length && vendas[0].status == 'delayed'" fluid>
      <h6 class="subtitulo">{{ $t("pg_obrigado.modal.agradecemos") }}</h6>
      <p class="paragrafo">
        {{ $t("pg_obrigado.modal.proximos_passos") }}

        <b-row v-if="Object.values(vendas).length ? vendas[0].product : false">
          <b-col cols="12 mt-1">
            <h6 class="detalhes-compra">
              {{ $t("pg_obrigado.modal.detalhes_compra") }}
            </h6>
            <div
              v-for="venda in vendas"
              :key="`${
                venda.sale_id ||
                venda.id ||
                venda.bump_id ||
                venda.chc_id ||
                venda.chc
              }-${venda.product ? venda.product.name : ''}`"
              class="item-venda"
            >
              <div class="dados-venda">
                <p>{{ $t("pg_obrigado.modal.codigo_transacao") }}</p>
                <p data-test="idsale">
                  #{{
                    venda.sale_id ||
                    venda.id ||
                    venda.bump_id ||
                    venda.chc_id ||
                    venda.chc
                  }}
                </p>
              </div>
              <div class="dados-venda">
                <p data-test="namesale">
                  <font-awesome-icon v-if="venda.status == 'error'"
                    icon="times"
                    class="icon-status icon-error"
                  />
                  <font-awesome-icon v-if="venda.status == 'success' || venda.status == 'paid' || venda.status == 'trialing'"
                    icon="check"
                    class="icon-status icon-success"
                  />
                  {{ venda.product ? venda.product.name : "--" }}

                  <span v-if="venda.status == 'error'" class="motive-error">
                    {{ checkError(venda.code) }}
                  </span>
                  <span v-if="venda.status == 'success' || venda.status == 'paid' || venda.status == 'trialing'"
                    class="motive-error"
                  >
                    {{ $t("pg_obrigado.modal.transacao") }}
                  </span>
                </p>
                <p data-test="pricesale">
                  {{
                    venda.local_currency && venda.local_currency.currency_symbol
                      ? venda.local_currency.currency_symbol
                      : "R$"
                  }}

                  {{ 
                    formatMoney(
                      venda.original_amount || venda.total || venda.product.amount || venda.amount
                    )
                  }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </p>
    </b-container>
    <!-- Frete Data -->
    <b-container v-if="
        !!vendas.length &&
        vendas[0].status != 'delayed' &&
        (!!vendas[0].shipping_amount || (!!vendas[0].shipping_selected && !vendas[0].shipping_amount && vendas[0].shipping_selected.service_name === 'GRÁTIS')) &&
        !!vendas[0].shipping_selected &&
        !!vendas[0].shipping_selected.company &&
        !!vendas[0].shipping_selected.company.name &&
        !!vendas[0].shipping_selected.company.picture &&
        !!vendas[0].shipping_selected.delivery_range
      "
      fluid
    >
      <div>
        <h6 class="detalhes-compra mb-3">Frete escolhido</h6>
        <div class="frete-item">
          <div class="infos-frete">
            <img :src="vendas[0].shipping_selected.company.picture" />
            <div class="info-envio">
              <span>
                {{ vendas[0].shipping_selected.name }}
              </span>
              <span v-if="vendas[0].shipping_selected.delivery_range">
                {{ vendas[0].shipping_selected.delivery_range.min }} à
                {{ vendas[0].shipping_selected.delivery_range.max }} dias úteis
              </span>
              <span class="frete-valor">
                <!-- R$ {{ formatMoney(+vendas[0].shipping_selected.price) }} -->
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <!-- Fixed data -->
    <b-container
      v-if="
        vendas.length && 
        vendas[0].has_shipping_fee &&
        vendas[0].shipping_amount && 
        !vendas[0].shipping_selected &&
        vendas[0].status != 'delayed'"
    >
      <h6 class="detalhes-compra mb-3">
        Frete fixo no valor de {{ vendas[0].local_currency.currency_symbol }} {{ formatMoney(vendas[0].shipping_amount) }}
      </h6>
    </b-container>
    <!-- Footer / Button -->
    <template v-slot:modal-footer="{}" class="d-flex">
      <b-button v-if="boleto_barcode_"
        class="btn-modal d-none"
        variant="info"
        @click="onClose"
      ></b-button>
      <b-button v-if="!boleto_barcode_"
        class="btn-modal"
        variant="info"
        data-test="entendido"
        @click="onClose"
      >
        {{ $t("pg_obrigado.modal.entendido") }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import Money from "@/mixins/money";
import errorPayment from "@/utils/errorPayment.js";
import moment from "moment";

export default {
  props: {
    vendas: {
      type: Array,
      default: () => [],
    },
    boleto_url: {
      type: String,
    },
    boleto_barcode: {
      type: String,
    },
    name_product: {
      type: String,
    },
    valor: {
      type: Number,
      default: 0,
    },
    parcelas: {
      type: Number,
      default: 1,
    },
    trial: {},
    boletos: {
      type: [Array, Object],
      required: false,
      default: () => []
    }
  },
  mixins: [Money],
  computed: {
    amountFormat() {
      if (this.valor) {
        if (this.boleto_barcode_) {
          return `${this.$t(
            "pg_obrigado.modal.text_header.pague"
          )} valor ${this.$t("pg_obrigado.modal.text_header.via_boleto")}`;
        } else {
          return `${this.$t("pg_obrigado.modal.text_header.info_completa")}`;
        }
      } else {
        return `${this.$t("pg_obrigado.modal.text_header.info_completa")}`;
      }
    },
  },
  data() {
    return {
      boleto_barcode_: null,
    };
  },
  watch: {
    boleto_barcode(e) {
      this.boleto_barcode_ = e;
    },
  },
  methods: {
    openLinkOxxo(){
      var link = this.vendas.length === 1 ? this.vendas[0].boleto_url : this.response.order.boleto_url;
      window.open(link, '_blank');
    },
    formatDate(e) {
      return moment(e).format("DD/MM/YYYY");
    },
    openModal() {
      if (
        this.vendas[0].method === "SAFETYPAY-CASH" ||
        this.vendas[0].method === "SENCILLITO" ||
        this.vendas[0].method === "SPEI" ||
        this.vendas[0].method === "EFECTY" ||
        this.vendas[0].method === "EFT" ||
        this.vendas[0].method === "OXXO"
      ) {
        this.boleto_barcode_ = this.vendas[0].boleto_barcode;
      }
      if (this.vendas[0].shipping_selected) {
        this.vendas[0].shipping_selected = JSON.parse(
          this.vendas[0].shipping_selected
        ).frete;
      }
    },
    checkError(error) {
      return errorPayment(error);
    },
    onClose() {
      this.$bvModal.hide("success-modal");
      this.$emit("close", this.vendas[0].token);
    },
    copy() {
      const el = document.querySelector(`#form-boleto-1`);
      el.removeAttribute("disabled");
      el.select();
      document.execCommand("copy");
      el.setAttribute("disabled", true);

      this.$bvToast.toast(this.$t("pg_obrigado.modal.codigo_copiado"), {
        title: this.$t("pg_obrigado.modal.copiado"),
        variant: "info",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    download(url) {
      window.open(url);
    },
    copyByElement(id) {
      const el = document.getElementById(id);
      el.removeAttribute("disabled");
      el.select();
      document.execCommand("copy");
      el.setAttribute("disabled", true);

      this.$bvToast.toast(this.$t("pg_obrigado.modal.codigo_copiado"), {
        title: this.$t("pg_obrigado.modal.copiado"),
        variant: "info",
        autoHideDelay: 5000,
        appendToast: true,
      });
    }
  }
};
</script>
<style>
.modal {
  overflow: auto;
}
.modal-content {
  padding: 20px 30px !important;
  animation: showTop 0.3s forwards !important;
}
.modal-content .modal-header {
  border-bottom: 1px solid #e4e4ec;
  border: none;
  border-radius: 0;
  padding-bottom: 25px;
}
.modal-content .modal-header h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  max-width: 300px;
}
.modal-body {
  padding: 10px 0;
}
.col-auto {
  padding: 0;
}
.modal-content .modal-header .close {
  background: url("../../assets/img/icon/fechar.svg") no-repeat center center;
  filter: invert(1);
  overflow: hidden;
  text-indent: -100px;
  color: transparent;
  outline: none;
  border: none;
}
.modal-content .modal-footer {
  /* border-top: 1px solid #e4e4ec; */
  margin: 0;
  border: none;
  border-radius: 0;
  padding: 10px;
}
/* novos */
.subtitulo {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}
.paragrafo {
  font-size: 14px !important;
  line-height: 1.5;
  color: var(--text-color);
  font-weight: normal !important;
}

.title-barcode {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
}
.form-copy {
  padding: 15px 20px !important;
  height: 45px;
  border: 2px solid #e4e4ec;
  color: #000;
  outline: none;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 5px;
  /* width: 100%; */
  flex: 1;
  margin-right: 1rem;
}
.form-copy:disabled {
  background: #f0f0f0 !important;
  border: none !important;
  color: #000 !important;
}

.detalhes-compra {
  font-weight: 600;
  margin: 0;
  color: #3483fa;
  margin-top: 15px;
}
.item-venda {
  padding: 15px 0;
}
.item-venda + .item-venda {
  margin-top: 15px;
  border-top: 1px solid #e4e4ec;
}

.dados-venda {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dados-venda + .dados-venda {
  margin-top: 7px;
}
.dados-venda p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}
.action-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 769px) {
  .action-item {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .action-item input,
  .action-item button {
    width: 100%;
    margin-bottom: 10px;
  }
}
.icon-status {
  position: absolute;
  left: -10px;
  margin-top: 5px;
}
.icon-error {
  color: #ff0c37;
}
.icon-success {
  color: #00af7b;
}
.motive-error {
  display: block;
  font-size: 12px;
  color: #333;
  font-weight: normal;
}
.info-envio {
  font-size: 15px;
}
.frete-item .infos-frete {
  width: 100% !important;
}
</style>