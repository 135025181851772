<template>
  <div>
    <!-- frete -->
    <b-container
      fluid
      v-if="
        vendas.length &&
        !!vendas[0].shipping_amount || (!!vendas[0].shipping_selected && !vendas[0].shipping_amount && vendas[0].shipping_selected.service_name === 'GRÁTIS') &&
        vendas[0].shipping_selected &&
        vendas[0].status != 'delayed'
      "
    >
      <!-- dados do frete -->
      <div class="mt-4">
        <h6 class="detalhes-compra mb-3">Frete escolhido</h6>
        <div class="frete-item">
          <div class="infos-frete">
            <img :src="vendas[0].shipping_selected.company.picture" />
            <div class="info-envio">
              <span>
                {{ vendas[0].shipping_selected.name }}
              </span>
              <span v-if="vendas[0].shipping_selected.delivery_range">
                {{ vendas[0].shipping_selected.delivery_range.min }} à
                {{ vendas[0].shipping_selected.delivery_range.max }} dias úteis
              </span>
              <span class="frete-valor">
                <!-- R$ {{ formatMoney(+vendas[0].shipping_selected.price) }} -->
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    vendas: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.info-envio {
  font-size: 15px;
}
.frete-item .infos-frete {
  width: 100% !important;
}
</style>