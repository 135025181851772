<template>
  <b-modal
    id="success-modal-pix"
    :title="amountFormat"
    :no-close-on-backdrop="true"
    size="lg"
    @shown="openModal"
    @hidden="onClose"
  >
    <DetalhesPix
      :vendas="vendas"
      :imgQrcode="imgQrcode"
      :qrcode="qrcode"
      :sales="dadosPix.sales"
      @show_details="updatePix"
      @closeModal="onClose"
    />

    <template v-slot:modal-footer="{}">
      <div></div>
      <!-- <b-button variant="info" class="btn-modal d-none" @click="copy">{{
        $t("pg_obrigado.pix.btn_text")
      }}</b-button> -->
    </template>
  </b-modal>
</template>
<script>
import Money from "@/mixins/money";
import errorPayment from "@/utils/errorPayment.js";
import DetalhesPix from "./DetalhesPix.vue";

export default {
  data() {
    return {
      amountFormat: "",
    };
  },
  components: {
    DetalhesPix,
  },
  props: {
    vendas: {
      type: Array,
      default: () => [],
    },
    qrcode: {
      type: String,
    },
    imgQrcode: {
      type: String,
    },
    name_product: {
      type: String,
    },
    valor: {
      type: Number,
      default: 0,
    },
    dadosPix: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  mixins: [Money],

  methods: {
    formatTitle(novo_pix = false) {
      if (novo_pix) {
        this.amountFormat = "Prazo para o pagamento encerrado";
      } else {
        if (this.valor && this.vendas[0].status != "delayed") {
          this.amountFormat = `${this.$t(
            "pg_obrigado.pix.text_header.pague"
          )} valor ${this.$t("pg_obrigado.pix.text_header.via_pix")}`;
        } else {
          this.amountFormat = `${this.$t(
            "pg_obrigado.pix.text_header.info_completa"
          )}`;
        }
      }
    },
    updatePix(data) {
      if (!data) {
        this.formatTitle(true);
      } else {
        this.formatTitle();
      }
    },
    openModal() {
      this.formatTitle();
      if (this.vendas[0].shipping_selected) {
        this.vendas[0].shipping_selected = JSON.parse(
          this.vendas[0].shipping_selected
        ).frete;
      }
    },
    checkError(error) {
      return errorPayment(error);
    },
    onClose() {
      this.$bvModal.hide("success-modal-pix");
      this.$emit("close", this.vendas[0].token);
    },
    copy() {
      const el = document.querySelector(`#form-qrcode-1`);

      el.removeAttribute("disabled");
      el.select();
      document.execCommand("copy");
      el.setAttribute("disabled", true);

      this.$bvToast.toast(this.$t("pg_obrigado.pix.codigo_copiado"), {
        title: this.$t("pg_obrigado.pix.copiado"),
        variant: "info",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
<style >
.modal {
  overflow: auto;
}
.modal-content {
  padding: 20px 30px !important;
  animation: showTop 0.3s forwards !important;
}
.modal-content .modal-header {
  border-bottom: 1px solid #e4e4ec;
  border: none;
  border-radius: 0;
  padding-bottom: 15px;
}
.modal-content .modal-header h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  max-width: 300px;
}
.modal-body {
  padding: 10px 0;
}
.col-auto {
  padding: 0;
}
.modal-content .modal-header .close {
  background: url("../../assets/img/icon/fechar.svg") no-repeat center center;
  filter: invert(1);
  overflow: hidden;
  text-indent: -100px;
  color: transparent;
  outline: none;
  border: none;
}
.modal-content .modal-footer {
  /* border-top: 1px solid #e4e4ec; */
  margin: 0;
  border: none;
  border-radius: 0;
  padding: 10px;
}
/* novos */
.subtitulo {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}
.paragrafo {
  font-size: 14px;
  line-height: 1.5;
  color: var(--text-color);
  font-weight: normal;
  margin: 0;
}
.paragrafo span {
  color: var(--text-color);
  font-weight: 600;
}
.paragrafo + .paragrafo {
  margin-top: 7px;
}
.paragrafo.informativo {
  font-size: 13px;
  margin-bottom: 15px;
}
.title-barcode {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
}
#form-qrcode {
  padding: 15px 20px !important;
  height: 55px;
  border: 1px solid #e4e4ec;
  color: #000;
  outline: none;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 5px;
  width: 100%;
}
#form-qrcode:disabled {
  background: #e4e4ec;
  border: none;
  color: #000 !important;
}
@media screen and (max-width: 480px) {
  #form-qrcode {
    height: 50px;
  }
}
.title-barcode {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
}
.segunda-opcao {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 0.5px solid #e4e4ec;
}

.icon-status {
  position: absolute;
  left: -10px;
  margin-top: 5px;
}
.icon-error {
  color: #ff0c37;
}
.icon-success {
  color: #00af7b;
}
.motive-error {
  display: block;
  font-size: 12px;
  color: #333;
  font-weight: normal;
}
</style>