<template>
  <div class="main-pix" data-anima="top">
    <div v-if="!loading">
      <div class="grid-pix" v-if="!novo_pix">
        <div class="qr-item" v-if="!sales">
          <b-img
            :src="imgQrcode"
            fluid
            alt="Pix Image"
            style="margin: 0 auto;"
            data-test="imgqrcode"
          ></b-img>

          <p>
            {{ $t("pg_obrigado.pix.expira_em") }}
            <span class="timer"> 00:{{ minutes }}:{{ seconds }} </span>
          </p>

          <b-button variant="info" class="btn-modal" @click="copyCode">{{
            $t("pg_obrigado.pix.btn_text")
          }}</b-button>

          <b-button
            v-if="isMobile"
            variant="closemodal"
            class="btn-modal btn-jafiz"
            @click="codetext = !codetext"
            >{{ !codetext ? $t("pg_obrigado.pix.ver_codigo") : $t("pg_obrigado.pix.fechar_codigo") }}</b-button
          >

          <div class="code-pix" v-if="!novo_pix && isMobile">
            <div class="space-break-code" v-if="codetext" data-anima="top">
              {{ qrcode }}
            </div>
          </div>

          <input
            v-model="qrcode"
            data-test="qrcode"
            disabled
            class="form-copy"
            :id="`form-qrcode-1`"
            type="hidden"
          />
        </div>
        <div class="w-100">
          <h6 class="subtitulo">{{ $t("pg_obrigado.pix.escaneie") }}</h6>
          <p class="paragrafo">
            <span>01.</span>
            {{ $t("pg_obrigado.pix.step_01") }}
          </p>
          <p class="paragrafo">
            <span>02.</span>
            {{ $t("pg_obrigado.pix.step_02") }}
          </p>
          <p class="paragrafo">
            <span>03.</span>
            {{ $t("pg_obrigado.pix.step_03") }}
          </p>
          <p class="paragrafo creditado">
            ⚡ {{ $t("pg_obrigado.pix.creditado_na_hora") }}
          </p>

          <div :class="{'grid-btns':!isMobile}" v-if="!sales">
            <b-button
              v-if="!isMobile"
              variant="closemodal"
              class="btn-modal btn-jafiz"
              @click="codetext = !codetext"
              >{{ !codetext ? $t("pg_obrigado.pix.ver_codigo") : $t("pg_obrigado.pix.fechar_codigo") }}</b-button
            >

            <!-- botao fecha modal -->
            <b-button
              variant="closemodal"
              class="btn-modal btn-jafiz"
              @click="closeModal"
              >{{ $t("pg_obrigado.pix.ja_fiz") }}</b-button
            >
          </div>
        </div>
      </div>

      <div class="code-pix" v-if="!novo_pix && !isMobile">
        <div class="space-break-code" v-if="codetext" data-anima="top">
          {{ qrcode }}
        </div>
      </div>

      <div class="grid-pix" v-if="novo_pix">
        <div class="qr-item">
          <div fluid class="sem-qr">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6.2V14L19.2 16.6M27 14C27 21.1797 21.1797 27 14 27C6.8203 27 1 21.1797 1 14C1 6.8203 6.8203 1 14 1C21.1797 1 27 6.8203 27 14Z"
                stroke="#3483FA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p>{{ $t("pg_obrigado.pix.expirou") }}</p>
          </div>
        </div>
        <div class="sem-pix">
          <h6 class="subtitulo">{{ $t("pg_obrigado.pix.prazo_expirado") }}</h6>
          <p class="paragrafo">
            {{ $t("pg_obrigado.pix.expirado_1") }}
          </p>
          <p class="paragrafo">
            {{ $t("pg_obrigado.pix.expirado_2") }}
          </p>

          <b-button
            variant="info"
            class="btn-modal btn-jafiz"
            @click="gerarNovoCodigo"
            >{{ $t("pg_obrigado.pix.gerar_novo") }}</b-button
          >
        </div>
      </div>
    </div>

    <div v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      loading: true,
      novo_pix: false,
      actualTime: moment().format("X"),
      minutes: 0,
      seconds: 0,
      codetext: false,
      client: {
        width: 0,
      },
    };
  },
  props: {
    imgQrcode: {
      type: String,
    },
    qrcode: {
      type: String,
    },
    sales: {
      type: Array,
      required: false,
      default: () => null
    }
  },
  watch: {
    actualTime() {
      this.compute();
    },
  },

  methods: {
    copyCode() {
      navigator.clipboard.writeText(this.qrcode);

      this.$bvToast.toast(this.$t("pg_obrigado.pix.codigo_copiado"), {
        title: this.$t("pg_obrigado.pix.copiado"),
        variant: "info",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    gerarNovoCodigo() {
      localStorage.removeItem(`timer-pix-${this.$route.query.s_id}`);
      let path = window.location.href.replace("obrigado", "");
      window.location.href = path;
    },
    updateTime() {
      this.actualTime = moment().format("X");
      setTimeout(() => {
        if (
          (this.minutes == "00" || this.minutes == 0) &&
          (this.seconds == "00" || this.seconds == 0)
        ) {
          // console.log(this.minutes, this.seconds);
          this.novo_pix = true;
          this.$emit("timerPix", false);
        } else {
          this.novo_pix = false;
          this.$emit("timerPix", true);

          this.updateTime();
        }
      }, 1000);

      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    getDiffInSeconds() {
      return (
        moment(
          localStorage.getItem(`timer-pix-${this.$route.query.s_id}`)
        ).format("X") - this.actualTime
      );
    },
    compute() {
      const duration = moment.duration(this.getDiffInSeconds(), "seconds");
      this.minutes =
        duration.minutes() > 0
          ? duration.minutes() < 10
            ? `0${duration.minutes()}`
            : duration.minutes()
          : "00";
      this.seconds =
        duration.seconds() > 0
          ? duration.seconds() < 10
            ? `0${duration.seconds()}`
            : duration.seconds()
          : "00";
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  computed:{
    isMobile() {
      return this.client.width <= 769;
    },
  },
  mounted() {
    setTimeout(() => {
      this.compute();
      this.updateTime();
    }, 1000);
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    if (!localStorage.getItem(`timer-pix-${this.$route.query.s_id}`)) {
      localStorage.setItem(
        `timer-pix-${this.$route.query.s_id}`,
        moment().add(60, "minutes").format()
      );
    }
  },
};
</script>

<style scoped>
.main-pix {
  margin: 30px 0;
}
#img-qrcode {
  margin: 0;
}

.grid-btns{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.btn-jafiz {
  margin-top: 30px;
  white-space: nowrap;
}
.grid-pix {
  display: flex;
  gap: 30px;
}
/* novos */
.subtitulo {
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.paragrafo {
  font-size: 14px;
  line-height: 1.5;
  color: var(--text-color);
  font-weight: normal;
  margin: 0;
}
.paragrafo span {
  color: var(--text-color);
  font-weight: 600;
}
.paragrafo + .paragrafo {
  margin-top: 20px;
}
.paragrafo.creditado {
  margin-top: 30px;
}
.paragrafo.informativo {
  font-size: 13px;
  margin-bottom: 15px;
}
.title-barcode {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
}

.code-pix{
  width: 100%;
}

.code-pix p, .code-pix p span{
  color: #3483fa;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -10px;
}

.space-break-code{
  word-break: break-all;
  text-align: start;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: -20px;
  border: 1px solid #ced4da;
  padding: 12px;
  margin-top: 30px;
  border-radius: 0.25rem;
}

#form-qrcode {
  padding: 15px 20px !important;
  height: 55px;
  border: 1px solid #e4e4ec;
  color: #000;
  outline: none;
  font-size: 15px;
  transition: 0.3s;
  border-radius: 5px;
  width: 100%;
}
#form-qrcode:disabled {
  background: #e4e4ec;
  border: none;
  color: #000 !important;
}
@media screen and (max-width: 480px) {
  #form-qrcode {
    height: 50px;
  }
}

.pointer{
  cursor:pointer;
}

/* qr item */
.qr-item {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
  width: -webkit-fill-available;
}
.qr-item p {
  margin: 8px 0 10px 0;
}
.qr-item button {
  width: 90%;
}
.timer {
  color: #3483fa;
  font-weight: 600;
}

/* sem pix */
.sem-qr {
  width: 178px;
  height: 178px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(52, 131, 250, 0.05);
  flex-direction: column;
  gap: 10px;
}
.sem-qr p {
  font-weight: 600;
  color: #3483fa;
}

@media screen and (max-width: 769px) {
  .grid-pix {
    display: grid;
    grid-template-columns: 1fr;
  }
  .btn-jafiz {
    width: 100%;
  }
}
</style>